import * as React from 'react'
import FeaturedPost from '../Components/Common/FeaturedPost'
import { HeroBanner } from '../Components/Common/HeroBanner'
import PostGrid from '../Components/Common/Posts/PostGrid'

interface IKnowledegeProps {
    location?: any
}

const KnowledgePage: React.FC<IKnowledegeProps> = ( {location}, props ) => {
    const [postType, setPostType] = React.useState(location?.state?.formartedTitle || 'News');

    return (
        <>
            <HeroBanner  pageSlug="knowledge" hasTabs={true} />
            {
                postType !== 'faqs' && (
                    <>
                        <FeaturedPost postType={postType} />
                        <PostGrid 
                            sectionTitle={location?.state?.title ? `Featured ${location?.state?.title}`: 'Featured Articles'} 
                            postType={{
                                type:postType,
                                title: location?.state?.title
                            }} 
                            hasPagination={false} 
                            hasViewAll={true}
                        />
                    </>
                ) 
            }
        </>
    )

}

export default KnowledgePage